import React from 'react';

const SaleListItem = (props) => {

  const { data } = props;

  return (
    <li>
      <section>
        <div>
          <span><b>{data.customer.name}</b><br/>Donated to <p style={{display: 'inline', textTransform: 'capitalize'}}>{`${data.userFirstName} ${data.userLastName}`}</p></span><strong>${data.paymentAmount}</strong>
        </div>
      </section>
    </li>
  );
}

export default SaleListItem;