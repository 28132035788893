import React from 'react';

const MembershipInviteListItem = (props) => {

  const { data } = props;

  return (
    <div className="ticker-item capitalize">{`${data.userFirst} ${data.userLast}`}: <b>{data.inviteGoal - data.numInvites}</b></div>
  );
}

export default MembershipInviteListItem;