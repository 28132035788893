import React from 'react';

const InviteListItem = (props) => {
  const { data } = props;

  const formatPlatformIcons = (platform) => {
    switch(platform) {
      case 'sms#email':
      case 'email#sms':
        return <><i className="fa-solid fa-envelope"></i><i className="fa-solid fa-comment-dots"></i></>;
      case 'email':
        return <i className="fa-solid fa-envelope"></i>;
      case 'sms':
        return <i className="fa-solid fa-comment-dots"></i>;
      default:
        return '';
    }
  };

  return (
    <li>
      <section>
        <div>
          <span><b className='capitalize'>{`${data.customer.firstName} ${data.customer.lastName}`}</b><br/>Invited by <p style={{display: 'inline', textTransform: 'capitalize'}}>{`${data.userFirstName} ${data.userLastName}`}</p></span><span className="icon-group">{
            data.platform &&
            formatPlatformIcons(data.platform)
          }</span>
        </div>
      </section>
    </li>
  );
}

export default InviteListItem;