import React, { useContext } from 'react';
import MetricViewContext from '../contexts/MetricViewContext';
import DefaultAvatar from '../assets/default-avatar.png';

const MembershipListItem = (props) => {

  const DEFAULT_PROFILE_IMAGE = DefaultAvatar;
  const { data } = props;
  const metric = useContext(MetricViewContext);

  const formatToUSCurrency = (value) => {
    let hasDecimal = value % 1 !== 0; // Check if there are decimals
    let formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: hasDecimal ? 2 : 0, // If has decimal, use 2 else 0
        maximumFractionDigits: hasDecimal ? 2 : 0  // If has decimal, use 2 else 0
    }).format(value);

    // If there's a decimal, wrap it in <sup> tags
    if (hasDecimal) {
        let parts = formattedValue.split(".");
        return `${parts[0]}.<sup>${parts[1]}</sup>`;
    }
    return formattedValue;
  }

  const value = metric === 'sale' ? formatToUSCurrency(data.totalSales) : data.numInvites;

  return (
    <li>
      <section>
        <div>
          <img alt="avatar" src={data.userImage || DEFAULT_PROFILE_IMAGE} />
          <span className="capitalize"><b>{data.rank}.&nbsp;&nbsp;</b>{`${data.userFirst} ${data.userLast}`}</span><strong className="us-currency" dangerouslySetInnerHTML={{ __html: value }}></strong>
        </div>
      </section>
    </li>
  );
}

export default MembershipListItem;