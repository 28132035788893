import React, { useEffect, useMemo, useState, useRef } from 'react';
import ReconnectingWebsocket from 'reconnecting-websocket';
import { useParams } from 'react-router-dom';
import MetricViewContext from '../contexts/MetricViewContext';
import { getDashboard } from '../services/dashboard.api';

import logo from '../assets/logo.png';

import InviteListItem from '../components/InviteListItem';
import MembershipInviteListItem from '../components/MembershipInviteListItem';
import MembershipListItem from '../components/MembershipListItem';
import PodiumPlacement from '../components/PodiumPlacement';
import SaleListItem from '../components/SaleListItem';
import TeamListItem from '../components/TeamListItem';
import TeamPodiumPlacement from '../components/TeamPodiumPlacement';
import Ticker from '../components/Ticker';

import Timer from '../components/Timer';

function Dashboard() {

  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState({});
  const [invites, setInvites] = useState({});
  const [memberships, setMemberships] = useState({});
  const [sales, setSales] = useState([]);
  const [teams, setTeams] = useState([]);

  const [metric, setMetric] = useState('sale');
  const [isLoading, setIsLoading] = useState(true);

  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const [isTimerStarted, setIsTimerStarted] = useState(false);

  const rws = useRef(null)
  const ref = useRef();

  useEffect(() => {

    try {
      async function fetchDashboard() {
        // Retrieve initial dashboard data
        const response = await getDashboard(campaignId);
  
        setCampaign(response.campaign);
        setInvites(response.invites);
        setMemberships(response.memberships.data.reduce((acc, membership) => {
          acc[`${membership.campaign}#${membership.user}`] = membership;
          return acc;
        }, {}));
        setSales(response.sales.data);
        setTeams(response.teams.data.reduce((acc, team) => {
          acc[team.id] = team;
          return acc;
        }, {}));
    
        setIsLoading(false);
      }
  
      fetchDashboard();
  
  
      rws.current = new ReconnectingWebsocket(`${process.env.REACT_APP_WEBSOCKET_URL}?context=${campaignId}&service=dashboard`);
  
      rws.current.onopen = (event) => {}
  
      return () => rws.current.close();
    } catch (err) {
      console.log("INITIALIZATION ERROR:", err);
    }

  }, [campaignId]);

  useEffect(() => {
    if (!rws.current) return;

    rws.current.onmessage = (message) => {
      const data = JSON.parse(message.data);

      if (data.eventType === 'processed_sales') {
  
        const salesTotal = data.sales.reduce((acc, sale) => {
          return acc + sale.paymentAmount;
        }, 0);

        const updatedMemberships = data.sales.reduce((acc, sale) => {
          const memberId = `${sale.campaign}#${sale.user}`;

          if (!acc.hasOwnProperty(memberId)) {
            acc[memberId] = {...memberships[memberId], totalSales: memberships[memberId].totalSales + sale.paymentAmount};
          } else {
            acc[memberId].totalSales = acc[memberId].totalSales + sale.paymentAmount;
          }
          return acc;
        }, {});

        const updatedTeams = data.sales.reduce((acc, sale) => {
          const teamId = sale.team;

          // Prevent sales associated with no team from being aggregated
          if (teamId === 'false') {
            return acc;
          }

          if (!acc.hasOwnProperty(teamId)) {
            acc[teamId] = {...teams[teamId], numSales: teams[teamId].numSales + sale.paymentAmount};
          } else {
            acc[teamId].numSales = acc[teamId].numSales + sale.paymentAmount;
          }
          return acc;
        }, {});

        setCampaign({...campaign, numSales: campaign.numSales + salesTotal});
        setMemberships({...memberships, ...updatedMemberships})
        setSales([...sales, ...data.sales]);
        setTeams({...teams, ...updatedTeams});
      }

      if (data.eventType === 'processed_invites') {

        const updatedTeams = data.invites.reduce((acc, invite) => {
          const teamId = invite.team;

          // Prevent invites associated with no team from being aggregated
          if (teamId === 'false') {
            return acc;
          }

          if (!acc.hasOwnProperty(teamId)) {
            acc[teamId] = {...teams[teamId], numInvites: teams[teamId].numInvites + 1};
          } else {
            acc[teamId].numInvites = acc[teamId].numInvites + 1;
          }
          return acc;
        }, {});

        setCampaign({...campaign, numInvites: campaign.numInvites + 1});
        setMemberships({
          ...memberships,
          [data.invites[0].membership]: {
            ...memberships[data.invites[0].membership],
            numInvites: memberships[data.invites[0].membership].numInvites + 1
          }
        });
        setInvites([...invites, ...data.invites]);
        setTeams({...teams, ...updatedTeams});
      }

      if (data.eventType === 'processed_membership') {
        const membership = data.membership;

        setCampaign({...campaign, numMembers: campaign.numMembers + 1});
        setMemberships({
          ...memberships,
          [`${membership.campaign}#${membership.user}`]: membership
        });
        if (membership.team && membership.team !== 'false') {
          setTeams({
            ...teams,
            [membership.team]: {
              ...teams[membership.team],
              numMembers: teams[membership.team].numMembers + 1
            }
          });
        }
      }

      if (data.eventType === 'processed_team') {
        const team = data.team;

        setTeams({
          ...teams,
          [team.id]: team
        });
      }

    }
  }, [campaign, memberships, invites, sales, teams]);

  const formatCurrency = (amount) => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const toggle = () => {
    if (metric === 'sale') {
      setMetric('invite');
    } else {
      setMetric('sale');
    }
  }

  const progress = metric === 'sale' ? (campaign.numSales/campaign.goal) * 100 : (campaign.numInvites/(campaign.userInviteGoal * campaign.numMembers)) * 100;

  const mappedMemberships = useMemo(() => {
    return Object.keys(memberships)
                .map(membership => memberships[membership])
                .filter(membership => membership.rank !== 4 && membership.rank !== 3)
                .sort((a, b) => {
                  let filter = 'totalSales';

                  switch(metric) {
                    case 'sale':
                      filter = 'totalSales';
                      break;
                    case 'invite':
                      filter = 'numInvites';
                      break;
                    default:
                      filter = 'totalSales';
                  }

                  return (a[filter] > b[filter]) ? -1 : ((a[filter] < b[filter]) ? 1 : 0) || 
                         a.userFirst.localeCompare(b.userFirst);
                });
  }, [metric, memberships]);

  const mappedTeams = useMemo(() => {
    return Object.keys(teams)
                .map(team => teams[team])
                .sort((a, b) => {
                  let filter = 'numSales';

                  switch(metric) {
                    case 'sale':
                      filter = 'numSales';
                      break;
                    case 'invite':
                      filter = 'numInvites';
                      break;
                    default:
                      filter = 'numSales';
                  }

                  return (a[filter]/(a.numMembers || 1) > b[filter]/(b.numMembers || 1)) ? -1 : ((a[filter]/(a.numMembers || 1) < b[filter]/(b.numMembers || 1)) ? 1 : 0) ||
                         a.name.localeCompare(b.name);
                });
  }, [metric, teams]);

  const membersWithInvites = useMemo(() => {
    return mappedMemberships.filter(membership => membership.numInvites < campaign.userInviteGoal)
                            .map(membership => ({...membership, inviteGoal: campaign.userInviteGoal}))
                            .sort((a, b) => a.userFirst.localeCompare(b.userFirst));
  }, [campaign.userInviteGoal, mappedMemberships]);

  return (
    <MetricViewContext.Provider value={metric}>
      <div className="layout flex" style={{maxWidth: '100vw'}}>
        {
          isLoading &&
          <div className="pulse" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vw'
          }}>
            <p style={{
              fontFamily: "Maven Pro, sans-serif",
              fontWeight: 600,
              fontSize: '1.6vw'
            }}>Preparing Live Dashboard</p>
          </div>
        }
        { !isLoading &&
        <>
        <div className="layout-header flex" style={{maxWidth: '100vw'}}>
          <div className="layout-header-left flex">
            <div className="layout-header-left-logo flex">
              <img src={campaign.organization_logo || logo} alt="" />
            </div>
            <div className="layout-header-left-heading flex">
              <h1>{campaign.name}</h1>
              <div>
                <b>{campaign.numMembers}</b>
                <span>&nbsp;Members</span>
                <span>&nbsp;&bull;&nbsp;</span>
                <b>{mappedTeams.length}</b>
                <span>&nbsp;Teams</span>
              </div>
            </div>
          </div>
          <div className="layout-header-right flex">
            <div className="settings">
              <div className="icon-container flex">
                <i className="fas fa-cog"></i>
                <div className="menu-buttons">
                  <button id="toggleButton" className={`toggle-switch ${metric === 'sale' ? 'active' : ''}`} onClick={toggle}>
                    <span className="switch-back flex"><span className="switch-knob flex"><i className={`fa-solid fa-circle-${metric === 'sale' ? 'dollar' : 'envelope'}`}
                          id="toggleIcon"></i></span></span>
                  </button>
                  <div className="menu-separator"></div>
                  { isTimerStarted && !isTimerPaused &&
                    <button id="pauseBtn"><i className="fas fa-pause-circle" onClick={() => ref.current?.pauseTimer()}></i></button>
                  }
                  { (!isTimerStarted || isTimerPaused) &&
                    <button id="startBtn"><i className="fas fa-play-circle" onClick={() => ref.current?.startTimer()}></i></button>
                  }
                  <button id="editBtn" onClick={() => ref.current?.editTimer()}><i className="fas fa-clock"></i></button>
                </div>
              </div>
            </div>
            <Timer
              initialTime={(() => {
                let cache = localStorage.getItem(campaignId);
                if (cache) {
                  const { currentTime } = JSON.parse(cache);
                  return currentTime;
                }
              })()}
              ref={ref}
              onEnd={() => localStorage.removeItem(campaignId)}
              onLoad={() => {
                let cache = localStorage.getItem(campaignId);
                if (cache) {
                  const { timerState } = JSON.parse(cache);
                  if (timerState === 'active') {
                    ref.current?.startTimer();
                  } else if (timerState === 'paused') {
                    setIsTimerStarted(true);
                    setIsTimerPaused(true);
                    ref.current?.startTimer();
                    ref.current?.pauseTimer();
                  }
                }
              }}
              onPause={isPaused => {
                setIsTimerPaused(isPaused);
                let cache = localStorage.getItem(campaignId);
                if (cache) {
                  localStorage.setItem(campaignId, JSON.stringify({...JSON.parse(cache), timerState: 'paused'}));
                }
              }}
              onStart={(isPaused, isStarted) => {
                setIsTimerPaused(isPaused);
                setIsTimerStarted(isStarted);
              }}
              onTick={(time, timerState) => {
                localStorage.setItem(campaignId, JSON.stringify({ currentTime: time, timerState }));
              }}
            />
          </div>
        </div>

        <div className="layout-content flex" style={{maxWidth: '100vw'}}>

          <div className="layout-content-list flex">
            <div className="layout-content-list-top flex">
              { mappedMemberships[1] &&
                <PodiumPlacement
                  placement={2}
                  value={metric === 'sale' ? mappedMemberships[1]?.totalSales : mappedMemberships[1]?.numInvites}
                  name={`${mappedMemberships[1]?.userFirst} ${mappedMemberships[1]?.userLast}`}
                  image={mappedMemberships[1]?.userImage}
                />
              }
              { mappedMemberships[0] &&
                <PodiumPlacement
                  placement={1}
                  value={metric === 'sale' ? mappedMemberships[0]?.totalSales : mappedMemberships[0]?.numInvites}
                  name={`${mappedMemberships[0]?.userFirst} ${mappedMemberships[0]?.userLast}`}
                  image={mappedMemberships[0]?.userImage}
                />
              }
              { mappedMemberships[2] &&
                <PodiumPlacement
                  placement={3}
                  value={metric === 'sale' ? mappedMemberships[2]?.totalSales : mappedMemberships[2]?.numInvites}
                  name={`${mappedMemberships[2]?.userFirst} ${mappedMemberships[2]?.userLast}`}
                  image={mappedMemberships[2]?.userImage}
                />
              }
            </div>
            <div className="layout-content-list-bottom">
              <ul>
                {
                  mappedMemberships.slice(3).map((membership, idx) => {
                    return <MembershipListItem
                      data={{...membership, rank: idx + 4}}
                      key={membership.user}
                    />
                  })
                }
              </ul>
            </div>
          </div>


          <div className="layout-content-middle flex">
            <div className="layout-content-middle-top flex">
              {/* <div id="currentAmount"><span id="currentValue" dangerouslySetInnerHTML={{__html: metric === 'sale' ? formatToUSCurrency(campaign.numSales) : campaign.numInvites}}></span></div> */}
              <div id="currentAmount"><b>{metric === 'sale' ? '$' : ''}</b><span id="currentValue">{metric === 'sale' ? formatCurrency(campaign.numSales) : campaign.numInvites}</span></div>
              <div id="progressContainer">
                <div id="progressBar" style={{width: `${progress}%`}}>{`${Math.round(progress)}%`}</div>
              </div>
              <div id="goalAmount">Goal: <b>{metric === 'sale' ? '$' : ''}</b><span id="goalValue">{metric === 'sale' ? formatCurrency(campaign.goal) : campaign.userInviteGoal * campaign.numMembers}</span></div>
            </div>
            <div className="layout-content-middle-bottom">
              <ul>
                { 
                  metric === 'sale' &&
                  sales.sort((a, b) => (a.created > b.created) ? -1 : ((a.created < b.created) ? 1 : 0)).map((sale) => {
                    return (
                      <SaleListItem data={sale} key={sale.id} />
                    );
                  })
                }
                {
                  metric === 'invite' &&
                  invites.sort((a, b) => (a.created > b.created) ? -1 : ((a.created < b.created) ? 1 : 0)).map(invite => {
                    return (
                      <InviteListItem data={invite} key={invite.inviteGroup} />
                    );
                  })
                }
              </ul>
            </div>
          </div>


          <div className="layout-content-list flex">
            <div className="layout-content-list-top flex">
              { mappedTeams[1] &&
                <TeamPodiumPlacement
                  placement={2}
                  value={metric === 'sale' ? (mappedTeams[1]?.numSales / (mappedTeams[1]?.numMembers || 1)) : (mappedTeams[1]?.numInvites / (mappedTeams[1]?.numMembers || 1))}
                  name={mappedTeams[1]?.name}
                />
              }
              { mappedTeams[0] &&
                <TeamPodiumPlacement
                  placement={1}
                  value={metric === 'sale' ? (mappedTeams[0]?.numSales / (mappedTeams[0]?.numMembers || 1)) : (mappedTeams[0]?.numInvites / (mappedTeams[0]?.numMembers || 1))}
                  name={mappedTeams[0]?.name}
                />
              }
              { mappedTeams[2] &&
                <TeamPodiumPlacement
                  placement={3}
                  value={metric === 'sale' ? (mappedTeams[2]?.numSales / (mappedTeams[2]?.numMembers || 1)) : (mappedTeams[2]?.numInvites / (mappedTeams[2]?.numMembers || 1))}
                  name={mappedTeams[2]?.name}
                />
              }
            </div>
            <div className="layout-content-list-bottom list-hover">
              <ul>
                {
                  mappedTeams.slice(3).map((team, idx) => {

                    const value = metric === 'sale' ? team.numSales/(team.numMembers || 1) : team.numInvites/(team.numMembers || 1);

                    return <TeamListItem
                      data={{...team, rank: idx + 4, value}}
                      key={team.id}
                    />
                  })
                }
              </ul>
            </div>

            {/* Modal for each team when it's clicked displays a separate html page showing team members */}
            <div id="teamModal" className="team-modal">
              <div className="team-modal-content flex">
                <span className="team-modal-close"><i className="fas fa-times"></i></span>
                <div id="teammodalBody"></div>
              </div>
            </div>

          </div>
        </div>

        <div className={`layout-footer flex ${membersWithInvites.length > 0 ? '' : 'minimize'}`}>
          <div className="layout-footer-left flex">
            <h2>INV. REMAINING</h2>
            <span className="badge">Goal:<b>{campaign.userInviteGoal}</b></span>
          </div>
          <div className="layout-footer-right">
            <div className="layout-footer-right-tiles" style={{maxMidth: '100%', overflow: 'hidden'}}>
              <Ticker
                items={membersWithInvites}
                ViewComponent={MembershipInviteListItem}
              />
            </div>
          </div>
        </div>
        </>
        }
      </div>
    </MetricViewContext.Provider>
  );
}

export default Dashboard