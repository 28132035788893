import React, { useCallback, useContext } from 'react';

import FirstPlaceLaurel from '../assets/Top 1.svg';
import SecondPlaceLaurel from '../assets/Top 2.svg';
import ThirdPlaceLaurel from '../assets/Top 3.svg';

import MetricViewContext from '../contexts/MetricViewContext';

const TeamPodiumPlacement = (props) => {

  const { name, placement, value } = props;
  const metric = useContext(MetricViewContext);

  const formatToUSCurrency = (value) => {
    let hasDecimal = value % 1 !== 0; // Check if there are decimals
    let formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: hasDecimal ? 2 : 0, // If has decimal, use 2 else 0
        maximumFractionDigits: hasDecimal ? 2 : 0  // If has decimal, use 2 else 0
    }).format(value);

    // If there's a decimal, wrap it in <sup> tags
    if (hasDecimal) {
        let parts = formattedValue.split(".");
        return `${parts[0]}.<sup>${parts[1]}</sup>`;
    }
    return formattedValue;
  }

  const formatToDecimal = (value) => {
    let hasDecimal = value % 1 !== 0; // Check if there are decimals
    let formattedValue = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: hasDecimal ? 2 : 0, // If has decimal, use 2 else 0
        maximumFractionDigits: hasDecimal ? 2 : 0  // If has decimal, use 2 else 0
    }).format(value);

    // If there's a decimal, wrap it in <sup> tags
    if (hasDecimal) {
        let parts = formattedValue.split(".");
        return `${parts[0]}.<sup>${parts[1]}</sup>`;
    }
    return formattedValue;
  }

  const setPlacementIndicator = useCallback((placement) => {
    switch(placement) {
      case 1:
        return FirstPlaceLaurel;
      case 2:
        return SecondPlaceLaurel;
      case 3:
        return ThirdPlaceLaurel;
    }
  }, [placement]);

  const setPlacementStyles = useCallback((placement) => {
    switch(placement) {
      case 1:
        return 'first';
      case 2:
        return 'second';
      case 3:
        return 'third';
    }
  }, [placement]);

  const setNameAbbreviation = useCallback((name) => {
    const parts = name.split(' ');

    if (parts.length > 1) {
      return parts[0].toLowerCase() === 'the' ?
                      parts[1].substring(0, 2)
                      : `${parts[0].substring(0, 1)}${parts[1].substring(0, 1)}`
    }

    return parts[0].substring(0, 2);
  });

  const val = metric === 'sale' ? formatToUSCurrency(value) : formatToDecimal(value);

  return (
    <button className={`${setPlacementStyles(placement)}-place modalLink flex`} href="#" data-file="team2.html">
      <div className="layout-content-list-top-amounts flex"><sub>avg.</sub><strong className="us-currency" dangerouslySetInnerHTML={{ __html: val}}></strong>
      </div>
      <img src={setPlacementIndicator(placement)} />
      <div className="team-pic flex">
        <span style={{textTransform: 'uppercase'}}>{setNameAbbreviation(name || '')}</span>
      </div>
      <div className="layout-content-list-top-name">
        <span>{name}</span>
      </div>
    </button>
  );
}

export default TeamPodiumPlacement;