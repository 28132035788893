import React, { useContext } from 'react';

import FirstPlaceLaurel from '../assets/Top 1.svg';
import SecondPlaceLaurel from '../assets/Top 2.svg';
import ThirdPlaceLaurel from '../assets/Top 3.svg';

import MetricViewContext from '../contexts/MetricViewContext';

import DefaultAvatar from '../assets/default-avatar.png';

const PodiumPlacement = (props) => {

  const { image, name, placement, value } = props;
  const metric = useContext(MetricViewContext);

  const formatToUSCurrency = (value) => {
    let hasDecimal = value % 1 !== 0; // Check if there are decimals
    let formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: hasDecimal ? 2 : 0, // If has decimal, use 2 else 0
        maximumFractionDigits: hasDecimal ? 2 : 0  // If has decimal, use 2 else 0
    }).format(value);

    // If there's a decimal, wrap it in <sup> tags
    if (hasDecimal) {
        let parts = formattedValue.split(".");
        return `${parts[0]}.<sup>${parts[1]}</sup>`;
    }
    return formattedValue;
  }

  const setPlacementIndicator = (placement) => {
    switch(placement) {
      case 1:
        return FirstPlaceLaurel;
      case 2:
        return SecondPlaceLaurel;
      case 3:
        return ThirdPlaceLaurel;
      default:
        return '';
    }
  };

  const setPlacementStyles = (placement) => {
    switch(placement) {
      case 1:
        return 'first';
      case 2:
        return 'second';
      case 3:
        return 'third';
      default:
        return '';
    }
  };

  const val = metric === 'sale' ? formatToUSCurrency(value) : value

  return (
    <div className={`${setPlacementStyles(placement)}-place flex`}>
      <div className="layout-content-list-top-amounts flex"><strong className="us-currency" dangerouslySetInnerHTML={{ __html: val}}></strong></div>
      <img alt={`${setPlacementIndicator(placement)} laurel`} src={setPlacementIndicator(placement)} />
      <div className="member-pic flex">
        <img alt="avatar" src={image || DefaultAvatar} />
      </div>
      <div className="layout-content-list-top-name">
        <span className="capitalize">{name}</span>
      </div>
    </div>
  );
}

export default PodiumPlacement;