import React, { useEffect, useState, useImperativeHandle, useMemo } from "react";

const Timer = React.forwardRef((props, ref) => {

    const {
        initialTime = 1 * 60 * 60,
        onEnd,
        onLoad,
        onStart,
        onPause,
        onTick
    } = props;

    const [ time, setTime ] = useState(initialTime);
    const [ isPaused, setIsPaused ] = useState(false);
    const [ isStarted, setIsStarted ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false);

    const [ modalTime, setModalTime ] = useState(time);

    useImperativeHandle(ref, () => ({
        startTimer: () => {
            if (!isStarted) {
                setIsStarted(true);
                onStart(false, true);
            }
            if (isPaused) {
                setIsPaused(false);
                onStart(false, true);
            }
        },
        pauseTimer: () => {
            setIsPaused(true);
            onPause(true);
        },
        editTimer: () => {
            if (!isStarted) {
                setIsEditing(true);
            } else {
                setIsPaused(true);
                onPause(true);
                setIsEditing(true);
            }
        }
    }));

    const getTime = (totalSeconds) => {
        // const hours = Math.floor(totalSeconds / 3600);
        // const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
        // const seconds = totalSeconds - hours * 3600 - minutes * 60;
        
        // setTime(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`);

        setTime(totalSeconds);
        setModalTime(totalSeconds);
    }

    // function startTimer() {
    //     timerInterval = setInterval(() => {
    //         totalSeconds -= 1;
    //         updateDisplay();
    //         if (totalSeconds <= 0) {
    //         clearInterval(timerInterval);
    //         timerElement.classList.add("blink");
    //         timerElement.style.color = "#39B54A";
    //         pauseBtn.disabled = true;
    //         }
    //     }, 1000);
    // }

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
        const seconds = totalSeconds - hours * 3600 - minutes * 60;
        
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }

    useEffect(() => {
        if (!isStarted && onLoad) onLoad();
        if (!isStarted) return;

        const interval = setInterval(() => {

            if (isPaused) return;

            if (time > 0) {

                let timerState = 'inactive';

                if (isStarted && isPaused) {
                    timerState = 'paused';
                } else if (isStarted && !isPaused) {
                    timerState = 'active';
                }

                getTime(time - 1);
                onTick(time - 1, timerState);
            } else {
                onEnd();
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [time, isPaused, isStarted, onEnd, onLoad, onTick]);

    const isTimerEnded = time <= 0;

    const timeParts = useMemo(() => {
        const currentHours = Math.floor(modalTime / 3600);
        const currentMinutes = Math.floor(
          (modalTime - currentHours * 3600) / 60
        );
        const currentSeconds =
          modalTime - currentHours * 3600 - currentMinutes * 60;
  
        return {
            hours: currentHours,
            minutes: currentMinutes,
            seconds: currentSeconds
        }
    }, [modalTime]);

    const setTimerStyle = () => {
        if (isStarted && isPaused) return { color: "#F15A24"};
        if (isStarted && isTimerEnded) return {color: "#39B54A"}

        return {};
    }

    const incrementValue = (type, delta) => {
        switch(type) {
            case 'hour':
                setModalTime(modalTime + (delta * 3600));
                break;
            case 'minute':
                setModalTime(modalTime + (delta * 60));
                break;
            case 'second':
                setModalTime(modalTime + delta);
                break;
            default:
                return modalTime;
        }
    }

    // onKeyUp={(evt) => {
    //     const numberKeys = [
    //        'Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9',
    //        'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9'
    //     ];

    //     if (numberKeys.includes(evt.key)) {
    //         incrementValue('hour', parseInt(Math.abs(timeParts.hours - evt.target.value) || 0, 10))
    //     }
    //   }}

    return (
        <div className="countdown flex">
          <div className="countdown-display flex">
            <div id="timer" className={isStarted && (isTimerEnded || isPaused) ? 'blink' : ''} style={setTimerStyle()}>{formatTime(time)}</div>

            {/* Countdown Modal */}
            <div id="modal" style={isEditing ? {display: 'flex'} : {}} onClick={() => {setIsEditing(false); setModalTime(time);}}>
              <section className="modal-form flex">

                {/* Hours Input */}
                <section onClick={(e) => e.stopPropagation()}>
                  <label htmlFor="hoursInput">Hours</label>
                  <input type="number" id="hoursInput" min="0" value={timeParts.hours} readOnly />
                  <div className="custom-spinners flex">
                    <button id="up-number" type="button" onClick={(e) => {e.stopPropagation(); incrementValue('hour', 1)}}><i
                        className="fas fa-caret-up"></i></button>
                    <button id="down-number" type="button" onClick={(e) => {e.stopPropagation(); incrementValue('hour', -1)}}><i
                        className="fas fa-caret-down"></i></button>
                  </div>
                </section>

                {/* Minutes Input */}
                <section onClick={(e) => e.stopPropagation()}>
                  <label htmlFor="minutesInput">Minutes</label>
                  <input type="number" id="minutesInput" min="0" max="59" value={timeParts.minutes} readOnly />
                  <div className="custom-spinners flex">
                    <button id="up-number" type="button" onClick={(e) => {e.stopPropagation(); incrementValue('minute', 1)}}><i
                        className="fas fa-caret-up"></i></button>
                    <button id="down-number" type="button" onClick={(e) => {e.stopPropagation(); incrementValue('minute', -1)}}><i
                        className="fas fa-caret-down"></i></button>
                  </div>
                </section>

                {/* <Seconds Input */}
                <section onClick={(e) => e.stopPropagation()}>
                  <label htmlFor="secondsInput">Seconds</label>
                  <input type="number" id="secondsInput" min="0" max="59" value={timeParts.seconds} readOnly />
                  <div className="custom-spinners flex">
                    <button id="up-number" type="button" onClick={(e) => {e.stopPropagation(); incrementValue('second', 1)}}><i
                        className="fas fa-caret-up"></i></button>
                    <button id="down-number" type="button" onClick={(e) => {e.stopPropagation(); incrementValue('second', -1)}}><i
                        className="fas fa-caret-down"></i></button>
                  </div>
                </section>

              </section>

              <button
                id="setBtn"
                className="flex"
                onClick={(e) => {
                    e.stopPropagation();
                    setTime(modalTime);
                    setIsEditing(false)
                }}>
                    OK
                </button>
              <button id="cancelBtn" style={{display: 'none'}}>Cancel</button>
            </div>

          </div>
        </div>
    );
});

export default Timer;



// let timerElement = document.getElementById("timer");
// let pauseBtn = document.getElementById("pauseBtn");
// let startBtn = document.getElementById("startBtn");
// let editBtn = document.getElementById("editBtn");
// let modal = document.getElementById("modal");
// let hoursInput = document.getElementById("hoursInput");
// let minutesInput = document.getElementById("minutesInput");
// let secondsInput = document.getElementById("secondsInput");
// let setBtn = document.getElementById("setBtn");
// let cancelBtn = document.getElementById("cancelBtn");

// let totalSeconds = 4 * 60 * 60;
// let timerInterval;

// function updateDisplay() {
//   let hours = Math.floor(totalSeconds / 3600);
//   let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
//   let seconds = totalSeconds - hours * 3600 - minutes * 60;
//   timerElement.textContent = `${String(hours).padStart(2, "0")}:${String(
//     minutes
//   ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
// }

// function startTimer() {
//   timerInterval = setInterval(() => {
//     totalSeconds -= 1;
//     updateDisplay();
//     if (totalSeconds <= 0) {
//       clearInterval(timerInterval);
//       timerElement.classList.add("blink");
//       timerElement.style.color = "#39B54A";
//       pauseBtn.disabled = true;
//     }
//   }, 1000);
// }

// // Added for custom input number spinners
// function incrementValue(inputId, delta) {
//   const inputElem = document.getElementById(inputId);
//   const currentValue = parseInt(inputElem.value, 10) || 0;
//   const newValue = currentValue + delta;

//   // Optionally: Set bounds for hours (0-72 - this can change or can be removed), minutes (0-59), and seconds (0-59)
//   if (inputId === 'hoursInput' && (newValue >= 0 && newValue <= 72)) {
//     inputElem.value = newValue;
//   } else if ((inputId === 'minutesInput' || inputId === 'secondsInput') && (newValue >= 0 && newValue <= 59)) {
//     inputElem.value = newValue;
//   } else if (inputId !== 'hoursInput' && inputId !== 'minutesInput' && inputId !== 'secondsInput') {
//     inputElem.value = newValue;
//   }
// }

// pauseBtn.addEventListener("click", () => {
//   clearInterval(timerInterval);
//   timerElement.classList.add("blink");
//   timerElement.style.color = "#F15A24";
//   pauseBtn.style.display = "none";
//   startBtn.style.display = "inline";
// });

// startBtn.addEventListener("click", () => {
//   timerElement.style.color = "";
//   timerElement.classList.remove("blink");
//   startBtn.style.display = "none";
//   pauseBtn.style.display = "inline";
//   startTimer();
// });

// editBtn.addEventListener("click", () => {
//   clearInterval(timerInterval);

//   let currentHours = Math.floor(totalSeconds / 3600);
//   let currentMinutes = Math.floor(
//     (totalSeconds - currentHours * 3600) / 60
//   );
//   let currentSeconds =
//     totalSeconds - currentHours * 3600 - currentMinutes * 60;

//   hoursInput.value = currentHours;
//   minutesInput.value = currentMinutes;
//   secondsInput.value = currentSeconds;

//   modal.style.display = "flex";
// });

// setBtn.addEventListener("click", () => {
//   let inputHours = hoursInput.value ? parseInt(hoursInput.value) : 0;
//   let inputMinutes = minutesInput.value
//     ? parseInt(minutesInput.value)
//     : 0;
//   let inputSeconds = secondsInput.value
//     ? parseInt(secondsInput.value)
//     : 0;

//   totalSeconds = inputHours * 3600 + inputMinutes * 60 + inputSeconds;
//   updateDisplay();
//   modal.style.display = "none";

//   if (totalSeconds > 0) {
//     timerElement.style.color = "";
//     timerElement.classList.remove("blink");
//     pauseBtn.disabled = false;
//     startBtn.style.display = "none";
//     pauseBtn.style.display = "inline";
//     startTimer();
//   }
// });

// cancelBtn.addEventListener("click", () => {
//   modal.style.display = "none";
// });

// startTimer();