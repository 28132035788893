import React, { useState } from 'react';
import logo from "../assets/login-logo.svg";
import { getCode } from '../services/code.api';
import { useNavigate } from 'react-router-dom';

function Code() {

  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await getCode(code.toLowerCase());
      navigate(`/campaigns/${response.data.campaign}`);
    } catch (err) {
      setHasError(true);
      setIsLoading(false);
    }
  }

  return (
    <div className="login-layout flex">
      <div className="login-layout-container flex">
        <img className="login-layout-container-logo" src={logo} alt="logo" />
        <form className="login-layout-container-content flex" onSubmit={handleSubmit}>
          <input
            autoFocus
            id="campaignCodeInput"
            onChange={e => setCode(e.target.value)}
            placeholder="Campaign Code"
            type="text"
          />
          <button
            id="loginButton"
            dangerouslySetInnerHTML={{__html: isLoading ? '<i class="fa-solid fa-spinner fa-spin"></i>' : 'View Campaign'}}
            type="submit"  
          ></button>
        </form>
        { hasError &&
          <div className="login-layout-container-message flex">
            <i className="fa-solid fa-triangle-exclamation"></i>
            <p id="errorMessage">Enter a valid campaign code to view the live dashboard.</p>
          </div>
        }
      </div>
    </div>
  );
}

export default Code