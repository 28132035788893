import React, { useContext } from 'react';
import MetricViewContext from '../contexts/MetricViewContext';

const TeamListItem = (props) => {

  const { data } = props;
  const metric = useContext(MetricViewContext);

  const formatToUSCurrency = (value) => {
    let hasDecimal = value % 1 !== 0; // Check if there are decimals
    let formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: hasDecimal ? 2 : 0, // If has decimal, use 2 else 0
        maximumFractionDigits: hasDecimal ? 2 : 0  // If has decimal, use 2 else 0
    }).format(value);

    // If there's a decimal, wrap it in <sup> tags
    if (hasDecimal) {
        let parts = formattedValue.split(".");
        return `${parts[0]}.<sup>${parts[1]}</sup>`;
    }
    return formattedValue;
  }

  const formatToDecimal = (value) => {
    let hasDecimal = value % 1 !== 0; // Check if there are decimals
    let formattedValue = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: hasDecimal ? 2 : 0, // If has decimal, use 2 else 0
        maximumFractionDigits: hasDecimal ? 2 : 0  // If has decimal, use 2 else 0
    }).format(value);

    // If there's a decimal, wrap it in <sup> tags
    if (hasDecimal) {
        let parts = formattedValue.split(".");
        return `${parts[0]}.<sup>${parts[1]}</sup>`;
    }
    return formattedValue;
  }

  const value = metric === 'sale' ? formatToUSCurrency(data.value.toFixed(2)) : formatToDecimal(data.value.toFixed(2));

  return (
    <li className="modalLink" href="#" data-file="team4.html">
      <section>
        <div>
          <span><b>{data.rank}.&nbsp;&nbsp;</b>{data.name}</span><small>avg.<strong className="us-currency" dangerouslySetInnerHTML={{ __html: value}}></strong></small>
        </div>
      </section>
    </li>
  );
}

export default TeamListItem;