import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import Code from './routes/Code';
import Dashboard from './routes/Dashboard';

function App() {
  return (
    <div style={{display: 'flex', flex: 1}}>
      <Router>
        <Routes>
          <Route path='/' element={<Code />} />
          <Route path='/campaigns/:campaignId' element={<Dashboard />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
