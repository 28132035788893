import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const Ticker = (props) => {

  const { items, ViewComponent } = props;

  const options = {
    // slidesToShow: 4,
    slidesToShow: items.length / 2 < 4 ? Math.ceil(items.length / 2) : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // No delay between slides
    arrows: false,
    dots: false,
    pauseOnHover: false,
    variableWidth: true,
    speed: 4000, // Duration of slide in milliseconds, adjust as needed
    cssEase: "linear",
    // infinite: true
  }

  return (
    <Slider {...options}>
      {items.map((item) => {
        return <ViewComponent data={item} key={item.user}/>
      })}
    </Slider>
  );
}

export default Ticker;